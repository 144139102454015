<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.out_plan_detail") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <!-- 仓库信息 -->
        <div>
          <a-row>
            <a-col :xxl="18" :xl="18" :lg="18" :md="18" :sm="24" :xs="24">
              <a-row :gutter="[0, 6]">
                <a-col :span="24">
                  <a-row :gutter="[0, 6]">
                    <a-col :xxl="8" :xl="10" :md="12" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.out_plan_name") }}:</label>
                      {{ plan.planName }}
                    </a-col>
                    <a-col :xxl="7" :xl="7" :md="14" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.creation_time") }}: </label>
                      {{ $filters.utcToCurrentTime(plan.creationTime) }}
                    </a-col>
                    <a-col :xxl="7" :xl="7" :md="10" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.update_time") }}: </label>
                      <span>
                        {{
                          $filters.utcToCurrentTime(plan.lastModificationTime)
                        }}</span
                      >
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="[0, 6]">
                    <a-col :xxl="8" :xl="10" :md="12" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.plan_no") }}:</label>
                      {{ plan.planNo }}
                      <a-button
                        class="ml-3"
                        type="primary"
                        ghost
                        :loading="printLoading"
                        size="small"
                        @click="handlePrintOutList"
                        >{{ $t("warehouse.print_out_the_list") }}</a-button
                      >
                    </a-col>
                    <a-col :xxl="16" :xl="14" :md="12" :sm="24" :xs="24">
                      <label
                        >{{ $t("warehouse.target_related_doc_no") }}:
                      </label>
                      <CPlanNumberItem :no="plan.toRelationPlanNo" />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="[0, 6]">
                    <a-col :xxl="8" :xl="10" :md="12" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.out_warehouse_no") }}:</label>
                      {{ plan.inventoryWarehouseAddress.no }}
                    </a-col>
                    <a-col :xxl="7" :xl="7" :md="14" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.out_warehouse_type") }}:</label>
                      {{
                        $t(
                          $enumLangkey(
                            "outWarehouseType",
                            plan.outWarehouseType
                          )
                        )
                      }}
                    </a-col>
                    <a-col :span="24">
                      <label>{{ $t("warehouse.warehouse_address") }}:</label>
                      {{ getAddress(plan.inventoryWarehouseAddress) }}
                      <span class="ml-2" v-if="plan.inventoryWarehouseAddress"
                        >({{ $t("logistics.contacts") }}:
                        {{ getLinkman(plan.inventoryWarehouseAddress) }})</span
                      >
                    </a-col>
                  </a-row>
                </a-col>

                <!-- 目标仓库代码(仓库间调拨有) -->
                <a-col
                  v-if="
                    plan.outWarehouseType ==
                      outWarehouseTypeEnum.betweenWarehouseAllocate ||
                    plan.outWarehouseType ==
                      outWarehouseTypeEnum.replaceSendWarehouseEntry 

                  "
                  :span="24"
                >
                  <label>{{ $t("warehouse.target_warehouse_code") }}:</label>
                  {{ plan.targetAddress.no }}
                </a-col>

                <!-- FBA仓库代码 -->
                <a-col
                  v-if="plan.outWarehouseType == outWarehouseTypeEnum.amazonFba"
                  :span="24"
                >
                  <label>{{ $t("warehouse.fba_warehouse_code") }}:</label>
                  {{ plan.targetAddress.name }}
                </a-col>

                <!-- FBm仓库代码 -->
                <a-col
                  v-if="
                    plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre
                  "
                  :span="24"
                >
                  <label>{{ $t("warehouse.fbm_warehouse_code") }}:</label>
                  {{ plan.targetAddress.name }}
                </a-col>
                <!-- 发至-->
                <a-col
                  v-if="
                    plan.outWarehouseType == outWarehouseTypeEnum.otherAddress
                  "
                  :span="24"
                >
                  <label>{{ $t("warehouse.destination") }}:</label>
                  {{ plan.targetAddress?.name }}
                </a-col>

                <!-- 运送地址(本地配送仓没有) -->
                <a-col
                  v-if="
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.destruction
                  "
                  :span="24"
                >
                  <label>{{ $t("warehouse.transport_goods_address") }}:</label>
                  {{ getAddress(plan.targetAddress) }}
                  <span v-if="plan.targetAddress.linkManPhone">
                    (+{{ plan.targetAddress.phoneCountryCode }}
                    {{ plan.targetAddress.linkManPhone }})
                  </span>
                  <template
                    v-if="
                      plan.outWarehouseType ==
                      outWarehouseTypeEnum.betweenWarehouseAllocate
                    "
                  >
                    <span class="ml-2 mr-2" v-if="plan.targetAddress"
                      >({{ $t("logistics.contacts") }}:
                      {{ getLinkman(plan.targetAddress) }})</span
                    >
                  </template>
                  <a-tag
                    v-if="
                      plan.targetAddress.countryId !=
                      plan.inventoryWarehouseAddress.countryId
                    "
                    color="warning"
                    >{{ $t("warehouse.international_transport") }}</a-tag
                  >
                </a-col>

                <a-col :span="24">
                  <a-row :gutter="[0, 6]">
                    <a-col
                      :xxl="8"
                      :xl="10"
                      :md="12"
                      :sm="24"
                      :xs="24"
                      v-if="
                        plan.outWarehouseType == outWarehouseTypeEnum.amazonFba
                      "
                    >
                      <label
                        >{{ $t("warehouse.amazon_shipping_number") }}:</label
                      >
                      {{ plan.amazonShippingNumber }}
                    </a-col>

                    <a-col
                      :xxl="8"
                      :xl="10"
                      :md="12"
                      :sm="24"
                      :xs="24"
                      v-if="
                        plan.outWarehouseType ==
                        outWarehouseTypeEnum.mercadolibre
                      "
                    >
                      <label>{{ $t("warehouse.seller_id") }}:</label>
                      {{ plan.amazonShippingNumber }}
                    </a-col>

                    <a-col :xxl="7" :xl="7" :md="14" :sm="24" :xs="24">
                      <label
                        v-if="
                          plan.outWarehouseType ==
                          outWarehouseTypeEnum.amazonFba
                        "
                        >{{ $t("warehouse.shipment_tracking_number") }}:</label
                      >
                      <label
                        v-if="
                          plan.outWarehouseType ==
                          outWarehouseTypeEnum.mercadolibre
                        "
                        >{{ $t("warehouse.warehousing_number") }}:</label
                      >
                      {{ plan.shipmentTrackingNumber }}
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="[0, 6]">
                    <a-col :xxl="8" :xl="10" :md="12" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.planned_quantity") }}:</label>
                      {{ plan.plannedQuantity }}
                    </a-col>
                    <a-col :xxl="7" :xl="7" :md="14" :sm="24" :xs="24">
                      <label>{{ $t("warehouse.planned_box_quantity") }}:</label>
                      {{ plan.plannedBoxQuantity }}
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-col>
            <a-col :xxl="6" :xl="6" :lg="6" :md="6" :sm="24" :xs="24">
              <div class="mt-3">
                <a-row type="flex">
                  <a-col flex="0 0 150px">
                    <a-row style="text-align: center" :gutter="[0, 10]">
                      <a-col :span="24">
                        <a-typography-text strong>
                          {{ $t("warehouse.status") }}</a-typography-text
                        >
                      </a-col>
                      <a-col :span="24">
                        <a-typography-text type="success">
                          {{
                            $t(
                              $enumLangkey("outPlanStatus", plan.outPlanStatus)
                            )
                          }}</a-typography-text
                        >
                      </a-col>
                      <a-col :span="24" class="mb-3">
                        <a-popconfirm
                          :title="$t('common.are_you_sure')"
                          :ok-text="$t('common.confirm')"
                          :cancel-text="$t('common.cancel')"
                          @confirm="handleAnewInWarehouse"
                        >
                          <a-button
                            type="primary"
                            v-if="isAnewInWarehouse"
                            size="small"
                            :loading="anewInWarehouseLoading"
                            ghost
                            >{{ $t("warehouse.anew_in_warehouse") }}</a-button
                          >
                        </a-popconfirm>
                      </a-col>
                    </a-row>
                  </a-col>

                  <a-col flex="0 0 150px">
                    <a-row style="text-align: center" :gutter="[0, 10]">
                      <a-col :span="24">
                        <a-typography-text strong>
                          {{ $t("warehouse.logistics_delivery_status") }}
                        </a-typography-text>
                      </a-col>
                      <a-col :span="24">
                        <a-typography-text
                          v-if="plan.deliveryStatus"
                          type="success"
                        >
                          {{
                            $t(
                              $enumLangkey(
                                "deliveryStatus",
                                plan.deliveryStatus
                              )
                            )
                          }}</a-typography-text
                        >
                        <span v-else>-</span>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>

        <!-- 列表 -->
        <div class="table">
          <a-table
            :columns="columns"
            :data-source="plan.tableList"
            size="small"
            :pagination="{
              defaultPageSize: defaultPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
          >
            <template #serialNumber>
              <span>
                {{ $t("warehouse.serial_number") }}
              </span>
            </template>
            <template #boxNoTitle>
              {{ $t("warehouse.box_no") }}
            </template>
            <template #boxNo="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <div>{{ record.box.boxNo }}</div>
                  <div>
                    <small>{{ record.box.selfBoxNo ?? "-" }}</small>
                  </div>
                </a-col>
                <a-col>
                  <CTagMixedOrSingle
                    :productKindCount="
                      record.box?.container?.productKindCount * 1
                    "
                  ></CTagMixedOrSingle>
                </a-col>
              </a-row>
            </template>

            <template #containerCount="{ record }">
              <div v-if="record.box?.container?.containerCount">
                {{ record.box?.container?.containerCount }} Unit
              </div>
              <div v-else>-</div>
            </template>

            <template #out_warehouse_info="{ record }">
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_size") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerLength">
                    {{ record.box?.container?.containerLength }}x{{
                      record.box?.container?.containerWidth
                    }}x{{ record.box?.container?.containerHeight }} cm</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_weight") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerWeight">
                    {{ gToKg(record.box?.container?.containerWeight) }} kg</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>

            <template #lableServe>
              <span>
                {{ $t("warehouse.label_serve_paid") }}
              </span>
            </template>

            <template #lableServeCustom="{ record }">
              <div
                v-if="
                  plan.outWarehouseType ==
                  outWarehouseTypeEnum.replaceSendWarehouseEntry
                "
              >
                {{
                  $t(
                    $enumLangkey("labelDispose", record.productLabelProcessing)
                  )
                }}
              </div>
              <template
                v-else-if="
                  plan.outWarehouseType !=
                  outWarehouseTypeEnum.betweenWarehouseAllocate
                "
              >
                <template v-if="record.serviceLabelImgUrl">
                  <div>{{ $t("warehouse.outer_box_label") }}</div>
                  <CFileDisplay
                    :fileUrl="record.serviceLabelImgUrl"
                    :useLocal="true"
                  ></CFileDisplay>
                  <div>{{ record.serviceLabelNo }}</div>
                </template>
                <span v-else>-</span>
              </template>
            </template>

            <template #title>
              <a-row justify="end">
                <a-col>
                  <a-button
                    type="primary"
                    ghost
                    @click="handleShowProductStatisticsModal"
                    >{{ $t("common.product_statistics") }}</a-button
                  >
                </a-col>
              </a-row>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation
                :disabled="!record.box.items || record.box.items?.length == 0"
                :open="expanded"
                @click="(e) => onExpand(record, e)"
              />
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="innerColumns"
                :data-source="record.box.items"
                :pagination="false"
                :row-key="(record, index) => index"
                size="small"
              >
                <template #product_info="{ record }">
                  <a-row :gutter="[12, 12]">
                    <a-col>
                      <CFileDisplay :fileUrl="record.productImgUrl" />
                    </a-col>
                    <a-col>
                      {{ record.productName }}
                      <div>
                        {{ record.productNo }}
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template #count="{ record }">
                  {{ record.containerCount }} Unit
                </template>
              </a-table>
            </template>

            <template #footer>
              <a-row type="flex" justify="start" class="mr-5" :gutter="32">
                <a-col
                  >{{ $t("common.total") }}: {{ plan.totalBox
                  }}{{ $t("warehouse.box") }}</a-col
                >
                <a-col
                  >{{ $t("warehouse.out_total_volume") }}:
                  {{ plan.outTotalVolume }}m<sup>3</sup></a-col
                >
                <a-col
                  >{{ $t("warehouse.out_total_weight") }}:
                  {{ plan.outTotalWeight }}kg</a-col
                >

                <a-col>
                  {{ $t("warehouse.transport_characteristics") }}:
                  <a-tag
                    color="default"
                    v-for="item in plan.transportCharacteristics"
                    :key="item"
                  >
                    {{ $t($enumLangkey("transportCharacteristics", item)) }}
                  </a-tag>
                </a-col>

                <a-col
                  v-if="
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.destruction
                  "
                >
                  <a-typography-text
                    v-if="plan.isSelectHitServe != 0"
                    type="danger"
                    >{{ $t("warehouse.selected_pallet_packing_serve") }}
                    {{ $t("warehouse.pallet_packing_tips") }}</a-typography-text
                  >
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>

        <!-- 提货信息 -->
        <div class="mt-3">
          <a-row>
            <a-col
              :span="4"
              v-if="
                plan.outWarehouseType !=
                outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                plan.outWarehouseType !=
                outWarehouseTypeEnum.destruction
              "
            >
              {{ $t("warehouse.transportation_delivery_way") }}:
              <span v-if="plan.transportationDeliveryWay">{{
                $t(
                  $enumLangkey(
                    "transportationDeliveryWay",
                    plan.transportationDeliveryWay
                  )
                )
              }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col
              :span="4"
              v-if="
                plan.transportationDeliveryWay ==
                transportationDeliveryWayEnum.takeTheir
              "
            >
              {{ $t("warehouse.pick_up_method") }}:
              <span>{{
                $t($enumLangkey("selfMentionType", plan.selfMentionType))
              }}</span>
            </a-col>
            <a-col
              :span="4"
              v-if="
                plan.transportationDeliveryWay ==
                  transportationDeliveryWayEnum.takeTheir &&
                plan.selfMentionType == selfMentionTypeEnum.truck
              "
            >
              {{ $t("warehouse.reserved_take_goods_info") }}:
              <span>{{ plan.reservedInfo }}</span>
            </a-col>
            <a-col
              >{{ $t("warehouse.payment") }}:
              <span v-if="plan.payWay">{{
                $t($enumLangkey("payWay", plan.payWay))
              }}</span
              ><span v-else>-</span></a-col
            >
          </a-row>
        </div>

        <!-- 物流方案 -->
        <div
          class="mt-3"
          v-if="
            plan.transportationDeliveryWay ==
              transportationDeliveryWayEnum.cwmLogistics &&
            logisticsSchemeList?.length > 0
          "
        >
          <!-- 模板计算物流方案 -->
          <div
            v-if="
              logisticsSchemeList?.length > 0 &&
              logisticsSchemeList[0]?.feeCalculationMethod ==
                feeCalculationMethodEnum.template
            "
          >
            <a-table
              :columns="columnsLogisticsScheme"
              :data-source="logisticsSchemeList"
              size="small"
              :scroll="{ x: true, y: 1000 }"
              :pagination="false"
            >
              <template #logisticsScheme>
                <span>
                  {{ $t("warehouse.logistics_scheme") }} /
                  {{ $t("warehouse.logistics_code") }}
                </span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("warehouse.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("warehouse.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("warehouse.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span>
                  {{ $t("warehouse.freight_estimate") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 8]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined
                      class="text-warning mr-1"
                      :style="{ fontSize: '12px' }"
                    />
                    <span
                      style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                    >
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{ record.daysMin }}-{{ record.daysMax }}
                  {{ $t("warehouse.workday") }}
                  ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{ $t("warehouse.average_unit_price") }}:
                  {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.averagePrice) }}
                </div>
                <div class="text-warning">
                  {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.expressFee) }}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod == 0">
                  {{ $t("logistics.chargeable_weight") }}:
                  {{ record.chargeableWeight }}kg
                </div>
                <div v-else>
                  {{ $t("logistics.chargeable_volume") }}:
                  {{ record.chargeableVolume }}m<sup>3</sup>
                </div>
                <div>
                  {{ $t("logistics.volume_weight_coefficient")
                  }}{{ record.volumeWeightParam }}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <template v-if="isSelectedDDP || isSelectedSignFee">
                  <div v-if="isSelectedDDP">
                    DDP ({{
                      $filters.formatCurrencyAmount(
                        record.currencySymbol,
                        record.logisticsRegionInfo.ddpFee,
                        false
                      )
                    }})
                  </div>

                  <div v-if="record.logisticsRegionInfo.signatureType">
                    <CSignSupported
                      v-model:value="record.logisticsRegionInfo.signatureType"
                      :symbol="record.currencySymbol"
                      :signFeeOptions="record?.logisticsRegionInfo.signFeeDtos"
                      :type="'readonly'"
                    />
                  </div>
                </template>
                <span v-else>-</span>
              </template>
            </a-table>
            <div class="mt-2">
              <a-tag color="warning" v-if="isRemoteArea">{{
                $t("logistics.remote_site")
              }}</a-tag>
              <template v-if="isCommercial != null">
                <a-tag color="warning" v-if="isCommercial">{{
                  $t("logistics.business_site")
                }}</a-tag>
                <a-tag color="warning" v-else>{{
                  $t("logistics.residence")
                }}</a-tag>
              </template>
            </div>

            <div class="mt-3" v-show="isShowVATInput">
              <label>VAT: </label>
              {{ VAT }}
            </div>
          </div>
          <!-- 卡车物流方案 -->
          <div v-else>
            <a-table
              :columns="columnsTruckScheme"
              :data-source="logisticsSchemeList"
              :scroll="{ x: 1000, y: 1000 }"
              size="small"
              :pagination="false"
            >
              <template #quoteOptionsTitle>
                <span>{{ $t("logistics.quote_options") }}</span>
              </template>

              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 8]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined
                      class="text-warning mr-1"
                      :style="{ fontSize: '12px' }"
                    />
                    <span
                      style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                    >
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>

              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>

              <template #quoteOptions="{ record }">
                <div>
                  <a-form-item :label="$t('logistics.quote_type')">
                    {{ record.truckBaseInfo.quoteType.name }}
                  </a-form-item>
                  <a-form-item
                    :label="$t('logistics.service_level')"
                    v-if="record.truckBaseInfo.serviceLevel"
                  >
                    {{ record.truckBaseInfo.serviceLevel.name }}
                  </a-form-item>
                  <a-form-item :label="$t('logistics.additional_options')">
                    <a-popover
                      placement="top"
                      v-if="record.truckBaseInfo.accessorials?.length > 0"
                    >
                      <template #content>
                        <div style="max-width: 350px">
                          <div
                            v-for="(item, index) in record.truckBaseInfo
                              .accessorials"
                            :key="index"
                          >
                            {{ item.name }}
                            <span
                              v-if="
                                index + 1 !=
                                record.truckBaseInfo.accessorials?.length
                              "
                              >,
                            </span>
                          </div>
                        </div>
                      </template>
                      <div
                        style="
                          max-width: 200px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <span
                          v-for="(item, index) in record.truckBaseInfo
                            .accessorials"
                          :key="index"
                        >
                          {{ item.name }}
                          <span
                            v-if="
                              index + 1 !=
                              record.truckBaseInfo.accessorials?.length
                            "
                            >,</span
                          >
                        </span>
                      </div>
                    </a-popover>
                    <span v-else> - </span>
                  </a-form-item>
                </div>
              </template>

              <template #quoteDetails="{ record }">
                <div v-if="record.truckBaseInfo">
                  {{ $t("logistics.carrier") }}:
                  {{ record.truckBaseInfo.carrierName }}
                  [
                  {{ record.truckBaseInfo.currencySymbol }}
                  {{
                    $filters.amountToFixed2(record.truckBaseInfo.totalCharge)
                  }}
                  ,
                  {{
                    isNaN(parseInt(record.truckBaseInfo.transitTime) + "")
                      ? record.truckBaseInfo.transitTime
                      : parseInt(record.truckBaseInfo.transitTime)
                  }}
                  {{ $t("common.days") }}
                  ]
                </div>
                <span v-else>-</span>
              </template>
            </a-table>
          </div>
        </div>

        <!-- 费用明细 -->
        <div class="mt-1">
          <CFee
            :fees="plan.warehouseFrees"
            :totalFee="plan.totalFee"
            class="mt-2"
          ></CFee>

          <a-row type="flex" justify="end" class="mb-5 mt-3">
            <a-col>
              <div style="width: 400px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                  <a-col>
                    <a-button type="primary" @click="handleBackOutPlan">{{
                      $t("common.backtrack")
                    }}</a-button>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      <CProductStatisticsModal ref="productStatisticsModalRef" />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick } from "vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Dropdown,
  Menu,
  Spin,
  Table,
  Input,
  Upload,
  Typography,
  Popconfirm,
  Select,
  Button,
  Form,
  Modal,
  Space,
  Popover,
  message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CImage from "../../components/CImage.vue";
import CFileDisplay from "../../components/CFileDisplay.vue";
import CProductStatisticsModal from "@/views/components/CProductStatisticsModal.vue";
import CHaveProductInformation from "../../components/CHaveProductInformation.vue";
import CTagMixedOrSingle from "../../components/CTagMixedOrSingle.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CFee from "../../components/CFee.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import localPrint from "../../../utils/localPrint";
import { useTab } from "../../../hooks/tabs/index";
import {
  getAddressByLanguageV2,
  gToKg,
  cmCubicToM,
  getLinkman,
} from "../../../utils/general";
import { printOutTheList } from "../../../api/modules/printUrl/pdf.js";
import {
  getOutPlanDetails,
  updateOutPlanName,
  confirmOutPlan,
  anewInWarehouse,
} from "../../../api/modules/storage/index";
import {
  outWarehouseType as outWarehouseTypeEnum,
  payWay as payWayEnum,
  transportationDeliveryWay as transportationDeliveryWayEnum,
  selfMentionType as selfMentionTypeEnum,
  productStatisticsType as productStatisticsTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
} from "../../../enum/enum.json";

export default {
  name: "storage_out_plan_details",
  components: {
    ASpin: Spin,
    AButton: Button,
    ATag: Tag,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AUpload: Upload,
    ATypographyText: Typography.Text,
    ASpace: Space,
    APopconfirm: Popconfirm,
    APopover: Popover,
    Content,
    CImage,
    CTagMixedOrSingle,
    CHaveProductInformation,
    CSignSupported,
    CProductStatisticsModal,
    CPlanNumberItem,
    CFileDisplay,
    CFee,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();

    const { delVisitedRoute, visitedRoutes } = useTab();
    const { getters } = useStore();
    const refInputPlanName = ref();
    const productStatisticsModalRef = ref();

    const innerColumns = [
      { width: 60 },
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "count",
        },
      },
    ];

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return (
            (currentPageIndex.value - 1) * defaultPageSize.value + (index + 1)
          );
        },
        width: 60,
      },
      {
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        },
        width: 100,
      },
      {
        title: "",
        width: 50,
      },
      {
        dataIndex: "containerCount",
        title: () => i18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "containerCount",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.out_warehouse_info"),
        slots: {
          customRender: "out_warehouse_info",
        },
        width: 200,
      },
      {
        dataIndex: "lableServe",
        slots: {
          title: "lableServe",
          customRender: "lableServeCustom",
        },
        width: 200,
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 120,
      },
      {
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 150,
      },
      {
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 150,
      },
    ];

    const columnsTruckScheme = [
      {
        title: () =>
          i18n.t("warehouse.logistics_scheme") +
          " / " +
          i18n.t("warehouse.logistics_code"),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 100,
      },
      {
        title: () => i18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 80,
      },
      {
        title: () => i18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 80,
      },
      {
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
        width: 150,
      },
      {
        title: () => i18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
        width: 100,
      },
    ];

    const state = reactive({
      columns: columns,
      outWarehouseTypeEnum: outWarehouseTypeEnum,
      payWayEnum: payWayEnum,
      buttonConfirmPlanLoading: false,
      pageLoading: false,
      buttonSavePlanNameLoading: false,
      anewInWarehouseLoading: false,
      isChangingPlanName: false,
      isAnewInWarehouse: false,
      printLoading: false,
      tempPlanName: "",
      plannedQuantity: null,
      plannedBoxQuantity: null,

      plan: {
        planId: "",
        planName: "",
        creationTime: null,
        lastModificationTime: null,
        inventoryWarehouseAddress: {
          //出库仓库
          no: "",
        },
        targetAddress: {
          //目标库
          warehouseType: "",
          warehouseNo: "",
          id: "", //仓库id
        },
        outWarehouseType: "",
        amazonShippingNumber: "",
        shipmentTrackingNumber: "",

        tableList: [],
        totalBox: "-",
        outTotalVolume: "-",
        outTotalWeight: "-",
        transportCharacteristics: null,
        isSelectHitServe: false,

        outPlanStatus: "",
        deliveryStatus: "",

        transportationDeliveryWay: "",
        reservedInfo: "",
        payWay: "",

        warehouseFrees: [],
        totalFee: null,
      },
      logisticsSchemeList: [],
      logisticsSchemeListTruck: [],
      isSelectedDDP: false,
      isSelectedSignFee: false,
      isShowVATInput: false,
      VAT: "", //税号

      isRemoteArea: null, //是否为偏远地址
      isCommercial: null, //true商业地址 false住宅地址  null不显示
    });

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleConfirmPlan = async () => {
      let par = {
        id: state.plan.planId,
        transDeliverType: null,
        transSelfMentionDistinguish: null,
        logisticsSchemeId: null,
      };
      state.buttonConfirmPlanLoading = true;
      confirmOutPlan(par)
        .then((res) => {
          message.success(i18n.t("common.succeed"));
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/storage/outplan" });
        })
        .finally(() => {
          state.buttonConfirmPlanLoading = false;
        });
    };

    const handleSavePlanName = async () => {
      let par = {
        id: state.plan.planId,
        planName: state.plan.planName,
      };
      state.buttonSavePlanNameLoading = true;
      updateOutPlanName(par)
        .then((res) => {
          message.success(i18n.t("common.amend_success"));
        })
        .finally(() => {
          state.buttonSavePlanNameLoading = false;
          state.isChangingPlanName = false;
        });
    };

    const handleCancelAlterPlanName = async () => {
      state.plan.planName = state.tempPlanName;
      state.isChangingPlanName = false;
    };

    const handleButtonAlterPlanName = async () => {
      state.isChangingPlanName = true;
      state.tempPlanName = state.plan.planName;
      nextTick(() => {
        refInputPlanName.value.focus();
      });
    };

    const funcOutPlanDetails = async (planId) => {
      state.pageLoading = true;
      getOutPlanDetails({ id: planId })
        .then((res) => {
          let r = res.result;
          state.isAnewInWarehouse = r.canReWarehouse;
          state.plan.inventoryWarehouseAddress = r.from;
          state.plan.targetAddress = r.to;

          state.plan.planId = r.id;
          state.plan.planName = r.planName;
          state.plan.creationTime = r.creationTime;
          state.plan.lastModificationTime = r.lastModificationTime;
          state.plan.planNo = r.planNo;
          state.plan.toRelationPlanNo = r.toRelationPlanNo;
          state.plan.warehouseNo = r.from.no;
          state.plan.outWarehouseType = r.outWarehouseType;
          state.plan.amazonShippingNumber = r.fbaAmazonTransNo;
          state.plan.shipmentTrackingNumber = r.fbaTranceNo;

          //计划数量
          state.plan.plannedQuantity = r.total.plan.totalWarehouseCount;
          state.plan.plannedBoxQuantity = r.total.plan.totalContainerBoxCount;

          state.plan.totalBox = r.total.plan.totalContainerBoxCount;
          state.plan.outTotalVolume = r.total.plan.totalVolume
            ? cmCubicToM(r.total.plan.totalVolume)
            : "-";
          state.plan.outTotalWeight = r.total.plan.totalWeight
            ? gToKg(r.total.plan.totalWeight)
            : "-";
          state.plan.transportCharacteristics =
            r.total.transportCharacteristics;
          state.plan.isSelectHitServe = r.daycareServiceId;
          state.plan.outPlanStatus = r.outWarehouseStatus;
          state.plan.deliveryStatus = r.transDeliverStatus;

          state.plan.transportationDeliveryWay = r.transDeliverType;
          state.plan.selfMentionType = r.selfMentionType;
          state.plan.reservedInfo = r.transSelfMentionDistinguish;
          state.plan.payWay = r.paymentMethodType;

          if (
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.betweenWarehouseAllocate ||
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.destruction
          ) {
            state.columns = columns.filter((x) => x.dataIndex != "lableServe");
          }

          state.plan.tableList = r.items;
          state.plan.warehouseFrees = r.fee?.fees?.map((x, index) => {
            return {
              key: index + 1,
              feeType: x.feeType,
              symbol: x.symbol,
              value: x.value,
            };
          });
          state.plan.totalFee = r.fee.totalFee ?? null;

          if (
            state.plan.transportationDeliveryWay ==
              transportationDeliveryWayEnum.cwmLogistics &&
            r.logistic?.logisticsBaseInfo
          ) {
            let l = r.logistic.logisticsBaseInfo;
            let logistic = {
              key: l.id,
              id: l.id,
              scheme: l.name,
              code: l.code,
              descriptionItems: l.descriptionItems,

              logisticsWay: l.logisticsType,
              deliveryWay: l.deliveryType,

              daysMin: l.daysMin,
              daysMax: l.daysMax,
              channelTimeType: l.channelTimeType, //签收或自提 枚举

              averagePrice: l.averagePrice, //平均费
              expressFee: l.totalFeeWithoutServices, //合计费用
              currencySymbol: l.currencySymbol, //货号符号

              chargeableWeight: l.chargeableWeight
                ? gToKg(l.chargeableWeight)
                : "", //计费重
              volumeWeightParam: l.volumeWeightParam, //体积系数
              chargeableVolume: cmCubicToM(l.chargeableVolume),
              logisticsCalcMethod: l.logisticsCalcMethod,
              truckBaseInfo: l.truckBaseInfo,
              feeCalculationMethod: l.feeCalculationMethod,
              logisticsRegionInfo: r.logistic.logisticsRegionInfo,
            };
            state.logisticsSchemeList.push(logistic);
            state.isSelectedDDP = r.logistic.logisticsRegionInfo.isChooseDdp;
            state.isSelectedSignFee =
              r.logistic.logisticsRegionInfo.isChooseSign;
            state.isShowVATInput = r.logistic.logisticsRegionInfo.vatCode;
            state.VAT = r.logistic.logisticsRegionInfo.vatCode;
            state.isRemoteArea = r.logistic.logisticsBaseInfo.isRemoteArea;
            state.isCommercial = r.logistic.logisticsBaseInfo.isCommercial;
          }
        })
        .catch((e) => {
          // console.log(e);
        })
        .finally(() => {
          state.pageLoading = false;
        });
    };

    const handleBackOutPlan = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/storage/outplan" });
    };

    const handleAnewInWarehouse = () => {
      state.anewInWarehouseLoading = true;
      anewInWarehouse({
        planId: state.plan.planId,
        warehouseType: state.plan.inventoryWarehouseAddress.warehouseType,
      })
        .then((res) => {
          message.success(i18n.t("common.succeed"));
          handleBackOutPlan();
        })
        .finally(() => {
          state.anewInWarehouseLoading = false;
        });
    };

    const handlePrintOutList = () => {
      state.printLoading = true;
      let boxLabelPdfUrl = printOutTheList(state.plan.planId);
      localPrint(boxLabelPdfUrl, "pdf")
        .catch((error) => {
          state.printLoading = false;
        })
        .finally(() => {
          state.printLoading = false;
        });
    };

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(
        productStatisticsTypeEnum.outWarehouse,
        route.params.id
      );
    };

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        funcOutPlanDetails(planId);
      }
    });

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);
    return {
      gToKg,
      feeCalculationMethodEnum,
      currentPageIndex,
      defaultPageSize,
      innerColumns,
      columns,
      ...toRefs(state),
      refInputPlanName,
      productStatisticsModalRef,
      transportationDeliveryWayEnum,
      selfMentionTypeEnum,
      handleConfirmPlan,
      getAddress,
      getLinkman,
      handleSavePlanName,
      handleCancelAlterPlanName,
      handleButtonAlterPlanName,
      handleAnewInWarehouse,
      handleBackOutPlan,
      handlePrintOutList,
      handleShowProductStatisticsModal,

      columnsLogisticsScheme,
      columnsTruckScheme,
    };
  },
};
</script>

<style lang="less" scoped></style>
